// Prefix module classes with m-

.m-menu__business {

    li {
        display: inline-block;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

}