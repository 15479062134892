// Prefix module classes with m-

.m-menu__footer {

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

}