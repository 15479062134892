// Prefix module classes with m-

.m-search {
	padding: 0 rem(20) rem(50) rem(20);

	&__author {
		color: $secondary;
		margin: 0 0 rem(20) 0;
	}

	&__col {
		flex: 0 0 100%;
		max-width: 100%;

		&:first-child {
			padding-bottom: rem(20);
		}

	}

	&__cols {
		background: white;
		box-shadow: 0px 0px 10px 5px $neutral_30;
		display: flex;
		flex-flow: row wrap;
		flex-direction: row-reverse;
		height: 100%;
		padding: rem(20);
	}

	&__item {
		flex: 0 0 100%;
		margin-bottom: rem(20);
		max-width: 100%;
		padding: 0 rem(10);

		a {
			text-decoration: none;
		}

		h2 {
			@include heading_size2();
			margin: 0 0 rem(20) 0;

			a {
				color: $accent_light;
			}

		}

		hr {
			border: none;
			border-top: 4px solid $accent_light;
			margin: 0 0 rem(20) 0;
			text-align: left;
			width: rem(40);
		}

		&-visual {
			background: {
				position: center;
				repeat: no-repeat;
				size: cover;
			}
			display: block;
			margin: 0;
			padding-bottom: 100%;
			width: 100%;
		}

	}

	&__reveal {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -10px;
	}

	.alm-btn-wrap {
		padding-top: rem(50);
		text-align: center;

		button {
			@include body_size3();
			background: {
				color: transparent;
				image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M5.42162 26.73132l44.60592 44.60592 44.60592-44.60592' fill='none' stroke='%23000' stroke-width='14.86864'/%3E%3C/svg%3E");
				position: center right;
				repeat: no-repeat;
				size: rem(16);
			}
			border: none;
			color: $primary;
			cursor: pointer;
			padding-right: rem(30);

			&:disabled {
				color: $primary;
				opacity: 0.2;
			}

		}

	}

	@media screen and (min-width:35em) {

		&__col {

			&:first-child {
				flex: 0 0 33.333%;
				max-width: 33.333%;
				padding-bottom: 0;
			}

			&:last-child {
				flex: 0 0 66.666%;
				max-width: 66.666%;
				padding-right: rem(30);
			}

		}

		&__cols {
			padding: rem(50) rem(30);
		}

	}

	@media screen and (min-width:50em) {
		padding: 0 rem(30) rem(50) rem(30);

		&__item {
			flex: 0 0 50%;
			max-width: 50%;
		}

	}

	@media screen and (min-width:70em) {
		padding: 0 0 rem(50) 0;
	}

}