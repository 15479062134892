// Prefix module classes with m-

.m-front-page-directory {
	background: white;

	&, a {
		color: white;
	}

	a {
		text-decoration: none;
	}

	&__categories {
		background: $primary;
		padding: rem(30) rem(20) 0 rem(20);

		h3 {
			@include body_size2();
			margin: 0;
		}

		img {
			margin: 0 auto rem(20) auto;
			width: rem(100);
		}

		li {
			flex: 0 0 50%;
			max-width: 50%;
			margin: 0 0 rem(20) 0;
			padding: 0 rem(20);
			text-align: center;
		}
	
		ul {
			display: flex;
			flex-flow: row wrap;
			list-style-type: none;
			margin: 0 -20px;
			padding: 0;
		}

	}

	&__cols {
		background-color: $accent_light;
	}

	&__info {
		@include font_semibold();
		padding: rem(30) rem(20);

		a {
			background: $primary;
			display: inline-block;
			padding: rem(15) rem(30);
		}

		h2, p {
			margin: 0 0 rem(30) 0;
		}

		h2 {
			@include heading_size2();
		}

		p {
			@include body_size2();
			color: $primary;
		}

	}

	@media screen and (min-width:40em) {

		&__categories {
		
			li {
				flex: 0 0 33.333%;
				max-width: 33.333%;
			}

		}

	}

	@media screen and (min-width:50em) {

		&__categories {
			padding: rem(50) rem(30) rem(30) rem(30);
		
			li {
				flex: 0 0 25%;
				max-width: 25%;
			}

		}

		&__info {
			padding: rem(50) rem(30);
		}

	}

	@media screen and (min-width:60em) {

		&__categories {
			
			li {
				flex: 0 0 33.333%;
				max-width: 33.333%;
			}

		}

		&__col {
			flex: 0 0 50%;
			max-width: 50%;
		}
	
		&__cols {
			align-items: center;
			display: flex;
		}

	}

	@media screen and (min-width:80em) {

		&__col {
			padding: rem(70) rem(100) rem(50) rem(100);
		}

		&__info {
	
			h2 {
				@include heading_size3();
			}

			p {
				@include body_size3();
			}
	
		}

	}

}