// Prefix module classes with m-

.m-business {

	&__container {
		margin: 0 auto;
		max-width: rem(800);
		padding-left: rem(20);
		padding-right: rem(20);

		a {
			color: $secondary;
		}

		h1 {
			color: $primary;
			font-weight: normal;
			size: rem(24);
		}

		.acf-fields > .acf-field {
			padding: 15px 0 !important;
		}

	}

}

.m-business-footer {
	color: white;
	padding: rem(50) 0;

	p {
		font-size: rem(14);
		line-height: 1.5;
		margin: 0;

		&:not(:last-child) {
			margin-bottom: rem(12);
		}

	}

}

.m-business-header {
	background: white;
	padding: rem(50) 0;

	&__logo {
		height: rem(67);
		margin-bottom: rem(25);
		width: rem(200);
	}

}

.m-business-main {
	background: $neutral_10;
	padding: rem(50) 0
}