// Prefix module classes with m-

.m-flexible-attractors {
	$self: &;
	background: white;
	padding: rem(30) rem(20) rem(10) rem(20);

	a {
		background: $accent_light;
		color: white;
		display: inline-block;
		padding: rem(15) rem(30);
		text-decoration: none;
	}

	h2 {
		@include font_semibold();
		@include heading_size2();
		clear: right;
		color: white;
		display: block;
		margin: 0 0 rem(20) 0;
	}

	p {
		@include body_size2();
		color: $accent_light;
		margin: 0 0 rem(50) 0;
	}

	&__icon {
		float: right;
		margin-bottom: rem(20);
		width: rem(90);
	}

	&__inner {
		background: $primary;
		height: 100%;
		padding: rem(20);
	}

	&__item {

		&:not(:last-child) {
			margin-bottom: rem(20);
		}

	}

	@media screen and (max-width:40em) {

		&__item {

			&:nth-child(odd) {

				#{$self}__inner {
					background: $accent_light;
	
					a {
						background: $primary;
					}
	
					p {
						color: $primary;
					}
	
				}

			}

		}

	}

	@media screen and (min-width:40em) {

		&__item {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 rem(10);
	
			&--2 {

				&:nth-child(4n+1),
				&:nth-child(4n+4),
				&:nth-child(4n+8),
				&:nth-child(8n+1),
				&:nth-child(8n+4),
				&:nth-child(8n+5),
				&:nth-child(8n+8),
				&:nth-child(9n+4),
				&:nth-child(12n+4) {

					#{$self}__inner {
						background: $accent_light;
		
						a {
							background: $primary;
						}
		
						p {
							color: $primary;
						}
		
					}

				}

			}

			&:last-child {
				margin-bottom: rem(20);
			}
	
		}

		&__items {
			display: flex;
			flex-flow: row wrap;
			margin: 0 -10px;
		}

	}

	@media screen and (min-width:40em) and (max-width:50em) {

		&__item {

			&--3 {

				&:nth-child(4n+1),
				&:nth-child(4n+4),
				&:nth-child(4n+8),
				&:nth-child(8n+1),
				&:nth-child(8n+4),
				&:nth-child(8n+5),
				&:nth-child(8n+8),
				&:nth-child(9n+4),
				&:nth-child(12n+4) {

					#{$self}__inner {
						background: $accent_light;
		
						a {
							background: $primary;
						}
		
						p {
							color: $primary;
						}
		
					}

				}

			}

		}

	}

	@media screen and (min-width:50em) {
		padding: rem(50) rem(30) rem(30) rem(30);

		&__item {

			&--3 {
				flex: 0 0 33.333%;
				max-width: 33.333%;

				&:nth-child(odd) {

					#{$self}__inner {
						background: $accent_light;
		
						a {
							background: $primary;
						}
		
						p {
							color: $primary;
						}
		
					}

				}

			}

		}

	}

	@media screen and (min-width:70em) {
		padding: rem(70) 0 rem(50) 0;

		h2 {
			@include heading_size3();
			width: calc(100% - 170px);
		}
	
		p {
			@include body_size3();
		}

		&__icon {
			width: rem(150);
		}

		&__inner {
			padding: rem(30) rem(70) rem(70) rem(70);
		}

	}

	&--no-gap {
		padding-bottom: 0;
	}

}