// Prefix module classes with m-

.m-flexible-copy-image {
	$self: &;
	background: white;
	color: $neutral_90;
	padding: rem(30) rem(20);

	#{ $self }__copy {

		a, h2 {
			@include font_semibold();
		}

		a {
			background: $neutral_90;
			color: white;
			display: inline-block;
			padding: rem(15) rem(30);
			text-decoration: none;
		}

		h2, p {
			margin: 0 0 rem(30) 0;
		}

		h2 {
			@include heading_size2();
		}

		p {
			@include body_size2();
		}

	}

	&--columns {
	
		#{ $self }__copy {
	
			div {
				padding: rem(20);
			}

			&--green {
				background: $accent_light;

				a {
					background: $primary;
				}

				h2 {
					color: white;
				}
		
				p {
					color: $primary;
				}

			}

			&--grey {
				background: $neutral_30;

				a {
					background: $primary;
				}

				h2 {
					color: $primary;
				}

			}

			&--white {

				a {
					background: $accent_light;
				}

				h2 {
					color: $accent_light;
				}

			}
		
		}

	}

	&--layered {
	
		#{ $self }__copy {
			margin-top: -50px;
			padding: 0 rem(20);
			position: relative;

			div {
				background: white;
				box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);
				padding: rem(20);
			}
	
			h2, p {
				color: $primary;
			}
		
		}

	}

	@media screen and (min-width:50em) {
		padding: rem(50) rem(30);

		#{ $self }__copy {

			div {
				padding: rem(30);
			}

		}

		#{ $self }__image {
			background: {
				position: center;
				repeat: no-repeat;
				size: cover;
			}

			img {
				display: none;
			}	

		}

		&--columns {

			#{ $self }__col {
				flex: 0 0 50%;
				max-width: 50%;
				min-height: rem(500);
			}
		
			#{ $self }__cols {
				display: flex;
	
				&--flipped {
					flex-direction: row-reverse;
				}
	
			}

			#{ $self }__copy {
				align-items: center;
				display: flex;
			}

		}

		&--layered {

			#{ $self }__copy {
				left: -20%;
				margin-top: 0;
				padding: rem(50) 0;

				div {
					padding: rem(30);
				}

			}

			#{ $self }__layer {
				flex: 0 0 60%;
				max-width: 60%;
				position: relative;
			}
		
			#{ $self }__layers {
				display: flex;
	
				&--flipped {
					flex-direction: row-reverse;
	
					#{ $self }__copy {
						left: 20%;
					}
	
				}
	
			}

		}

	}

	@media screen and (min-width:70em) {
		padding: rem(70) rem(30);

		#{ $self }__copy {

			h2 {
				@include heading_size3();
			}

			p {
				@include body_size3();
			}

		}

		&--columns {

			#{ $self }__copy {
		
				div {
					padding: rem(70);
				}

			}

		}

		&--layered {

			#{ $self }__copy {
				padding: rem(70) 0;

				div {
					padding: rem(70);
				}

			}

		}

	}

	@media screen and (min-width:90em) {
		padding: rem(70) 0;
	}

	&--no-gap {
		padding-bottom: 0;
	}

}