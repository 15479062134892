// Prefix module classes with m-

.m-menu__primary {
  $self: &;
  margin-top: rem(20);

  a {
    @include body_size2();
    @include font_semibold();
    color: $primary;
    display: block;
    line-height: rem(36);
    text-decoration: none;
    transition: all 0.3s ease;

    &:focus,
    &:hover {
      color: $accent_light;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  > ul {
    > .menu {
      &-item {
        display: inline-block;
        position: relative;

        &:not(:last-child) {
          margin-right: rem(12);
        }
      }
    }
  }

  .current-menu-item,
  .current-menu-parent,
  .current-page-ancestor,
  .current_page_parent {
    > a {
      color: $accent_light;
    }
  }

  .sub-menu {
    background: white;
    border: 1px solid $accent_light;
    display: none;
    left: -12px;
    list-style-type: none;
    position: absolute;
    top: 100%;
    width: rem(250);
    z-index: 1000;

    a {
      color: $primary;
      display: block;
      padding: rem(12);
      transition: background-color 0.3s ease;
      width: 100%;

      &:focus,
      &:hover {
        background: $accent_light;
        color: white;
      }
    }

    .current_page_item a {
      background: $accent_light;
      color: white;
    }

    > .menu {
      &-item {
        &:not(:first-child) {
          border-top: 1px solid $accent_light;
        }
      }
    }
  }

  .m-menu__primary--button {
    a {
      color: white;
      background: $accent_light;
      padding: 0 rem(20);

      &:focus,
      &:hover {
        color: $accent_light;
        background: $primary;
      }
    }

    &.current-menu-item,
    &.current_page_item {
      a {
        color: $accent_light;
        background: $primary;
      }
    }
  }

  &--opensub {
    .sub-menu {
      animation: {
        duration: 0.7s;
        fill-mode: forwards;
        iteration-count: 1;
        name: fadein;
      }
      display: block;
    }
  }

  @media screen and (min-width: 65em) {
    > ul {
      > .menu {
        &-item {
          &:not(:last-child) {
            margin-right: rem(20);
          }
        }
      }
    }
  }

  &-mobile {
    margin-bottom: rem(30);

    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;

      > .menu-item {
        &:not(:last-child) {
          margin-bottom: rem(10);
        }

        a {
          color: white;
          text-decoration: none;
          transition: color 0.3s ease;

          &:focus,
          &:hover {
            color: $accent_light;
          }
        }

        > a {
          @include font_semibold();
          @include heading_size2();
        }

        > .sub-menu {
          display: none;
          list-style-type: none;
          margin: rem(5) 0 0 rem(20);
          padding: 0;

          a {
            @include body_size2();
          }
        }
      }

      > .m-menu__primary-mobile-item--opensub {
        .sub-menu {
          animation: {
            duration: 0.7s;
            fill-mode: forwards;
            iteration-count: 1;
            name: fadein;
          }
          display: block;
        }
      }

      .current-menu-item,
      .current-menu-parent,
      .current-page-ancestor,
      .current_page_parent {
        > a {
          color: $accent_light;
        }
      }
    }

    @media screen and (min-width: 50em) {
      .menu {
        > .menu-item {
          > a {
            @include heading_size3();
          }

          > .sub-menu {
            a {
              @include body_size3();
            }
          }
        }
      }
    }
  }
}

// Forced navigation states

.error404,
.search-results,
.single-event,
.single-insight {
  .m-menu__primary {
    .menu {
      > .current_page_parent {
        > a {
          color: $primary;

          &:focus,
          &:hover {
            color: $accent_light;
          }
        }
      }
    }
  }
}

.single-event {
  .m-menu__primary {
    .menu {
      > .menu-item {
        > a[href*="/events"] {
          color: $accent_light;
        }
      }
    }
  }
}

.single-insight {
  .m-menu__primary {
    .menu {
      > .menu-item {
        > a[href*="/insights"] {
          color: $accent_light;
        }
      }
    }
  }
}
