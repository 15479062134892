.m-cookie-notice {
	@include body_size2();
	@include font_regular();
    background-color: tint($accent_dark,80%);
	border-top: 4px solid tint($accent_dark,50%);
	bottom: 0;
	color: $primary;
	position: fixed;
	text-align: center;
	transition: bottom 0.3s ease-in;
	width: 100%;
    z-index: 1000;

    a {
		color: $accent_dark;
		text-decoration: none;
		transition: color 0.3s ease-in;

		&:focus, &:hover {
			color: $primary;
		}

	}

	p {
		margin: 0;
		padding: rem(20);
    }

    &--hidden {
		bottom: -200px;
	}

}