// Prefix module classes with m-

.m-businesses {
	padding: 0 rem(20) rem(50) rem(20);

	&__address, &__categories {
		color: $neutral_50;
	}

	&__address {
		margin-top: rem(20);
	}

	&__categories {
		@include body_size1();
		margin-bottom: rem(20);

		a {
			color: $accent_light;
			text-decoration: none;
		}

		li {
			display: inline-block;

			&:not(:last-child)::after {
				content: '/';
				display: inline-block;
				padding: 0 rem(5);
			}

		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

	}

	&__cta {
		padding-top: rem(20);

		&--page, &--website {
			@include body_size3();
			display: inline-block;
			text-decoration: none;

			span {
				background: {
					color: transparent;
					position: center right;
					repeat: no-repeat;
					size: rem(24);
				}
				display: block;
				padding-right: rem(36);
			}

		}

		&--page {
			background: $accent_light;
			padding: rem(5) rem(10);

			span {
				background: {
					image: 
					url("data:image/svg+xml,%3Csvg viewBox='0 0 100 50' width='100' height='50' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M93.285572 22.8392924L74.0693052 8.0474794v29.58886204L93.285572 22.8392924z' fill-rule='nonzero'/%3E%3Cpath d='M93.285572 25.1719233l-19.2162668-14.791813v29.58886204L93.285572 25.1719233z' fill='%23fff' stroke='%23fff' stroke-width='7.8540600000000005'/%3E%3Cpath d='M78.33384716 25.46940624H.8980516' fill='%23fff' stroke='%23fff' stroke-width='8.0111412' stroke-linejoin='round' stroke-miterlimit='1.5'/%3E%3C/svg%3E");
				}
				color: white;
			}

		}

		&--website {

			span {
				background: {
					image: 
					url("data:image/svg+xml,%3Csvg viewBox='0 0 100 50' width='100' height='50' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M93.285572 22.8392924L74.0693052 8.0474794v29.58886204L93.285572 22.8392924z' fill-rule='nonzero'/%3E%3Cpath d='M93.285572 25.1719233l-19.2162668-14.791813v29.58886204L93.285572 25.1719233z' fill='#{encodecolor($primary)}' stroke='#{encodecolor($primary)}' stroke-width='7.8540600000000005'/%3E%3Cpath d='M78.33384716 25.46940624H.8980516' fill='#{encodecolor($primary)}' stroke='#{encodecolor($primary)}' stroke-width='8.0111412' stroke-linejoin='round' stroke-miterlimit='1.5'/%3E%3C/svg%3E");
				}
				color: $primary;
			}

		}

	}

	&__info {
		padding-top: rem(20);

		h2 {
			@include heading_size2();
			color: $primary;
			margin: 0 0 rem(12) 0;
		}

		p {
			margin: 0;
		}

	}

	&__item {
		border-bottom: 1px solid $neutral_30;
		padding: rem(30) 0;
	}

	&__logo {
		width: rem(140);
	}

	&--members {

		.alm-btn-wrap {
			padding-top: rem(50);
			text-align: center;

			button {
				@include body_size3();
				background: {
					color: transparent;
					image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M5.42162 26.73132l44.60592 44.60592 44.60592-44.60592' fill='none' stroke='%23000' stroke-width='14.86864'/%3E%3C/svg%3E");
					position: center right;
					repeat: no-repeat;
					size: rem(16);
				}
				border: none;
				color: $primary;
				cursor: pointer;
				padding-right: rem(30);

				&:disabled {
					color: $primary;
					opacity: 0.2;
				}

			}

		}

	}

	&--partners {

		.alm-btn-wrap {
		
			button {
				display: none !important;
			}
	
		}

	}

	@media screen and (min-width:40em) {

		&__cta {
			padding: rem(20) 0 0 rem(200);
			width: 100%;
		}

		&__info {
			padding: 0 rem(50);
			width: calc(100% - 150px);
		}

		&__item {
			display: flex;
			flex-flow: row wrap;
		}

		&__logo {
			width: rem(150);
		}

	}

	@media screen and (min-width:50em) {

		&__address {
			margin-top: rem(30);
		}

		&__cta {
			align-self: flex-end;
			padding: 0;
			width: rem(175);

			&--website {
				margin-left: rem(10);
			}

		}

		&__info {
			padding: 0 rem(50);
			width: calc(100% - 350px);
		}

		&__item {
			padding: rem(50) 0;
		}

		&__logo {
			width: rem(175);
		}

	}

	@media screen and (min-width:75em) {
        padding: 0 0 rem(50) 0;
    }

}