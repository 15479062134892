/*

Base rules

A Base rule is applied to an element using an element selector, a descendent selector, or a child selector, along with any pseudo-classes.

It doesn’t include any class or ID selectors. It is defining the default styling for how that element should look in all occurrences on the page.

There should be no need to use !important in a Base style.

http://smacss.com/book/type-base

*/

/*
All elements use the alternate box model, but any component added to your page (e.g. a third party embed) that expects the default box model can continue to use that model.
https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
*/
html { box-sizing: border-box; }
*, *:after, *:before { box-sizing: inherit; }

body {
	@include font_regular();
	background: $primary;
	color: $neutral_90;
}

b, strong {
	@include font_semibold();
}

img, input[src$=".svg"], svg, video {
	/* Fix for browsers where SVG images don’t scale in proportion correctly */
	display: block;
	height: auto;
	width: 100%;
}

input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], select, textarea {
	/* Stops Safari adding styling malarkey */
	-webkit-appearance: none;
}

input[type="email"], input[type="number"], input[type="image"], input[type="tel"], input[type="text"], input[type="url"], select, textarea {
	-webkit-border-radius: 0;
	border-radius: 0;
}

main {
	background: $neutral_10;
}

.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}