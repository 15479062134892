// Prefix module classes with m-

.m-flexible-columns {
	@include body_size2();
	background: white;
	padding: rem(30) rem(20);

	a {
		text-decoration: none;
	}

	blockquote, h2, h3, img, ol, p, ul {
		margin: 0;

		&:not(:last-child) {
			margin-bottom: rem(20);
		}

	}

	blockquote, h2, h3 {
		@include font_semibold();
	}

	blockquote {
		@include body_size3();
		border-left: 2px solid $neutral_90;
		padding-left: rem(30);
	}

	h2 {
		@include heading_size2();
	}

	h3 {
		@include heading_size1();
	}

	li {
		margin: 0;

		&:not(:last-child) {
			margin-bottom: rem(12);
		}

	}

	ol, ul {
		padding-left: rem(20);
	}

	&__col {
		
		&:not(:last-child) {
			padding-bottom: rem(30);
		}

	}

	&--blue {
		background: $primary;
		color: white;

		a, h2, h3, li::marker {
			color: $accent_light;
		}

		blockquote {
			border-color: $accent_light;
		}

	}

	&--green {
		background: $accent_light;
		color: white;

		a, li::marker {
			color: $primary;
		}

		blockquote {
			border-color: white;
		}

	}

	&--grey {
		background: $neutral_10;

		a, blockquote, li::marker {
			color: $accent_light;
		}

		blockquote {
			border-color: $primary;
		}

		h2, h3 {
			color: $primary;
		}

	}

	&--white {

		a, blockquote, li::marker {
			color: $accent_light;
		}

		blockquote {
			border-color: $primary;
		}

		h2, h3 {
			color: $primary;
		}

	}

	@media screen and (min-width:40em) {

		&__col {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 rem(20);
	
			&--2 {
				flex: 0 0 50%;
				max-width: 50%;
			}
	
		}
	
		&__cols {
			display: flex;
			flex-flow: row wrap;
			margin: 0 -20px;
		}

	}

	@media screen and (min-width:50em) {
		padding: rem(50) rem(30);

		&__col {

			&:not(:last-child) {
				padding-bottom: 0;
			}
		
			&--3 {
				flex: 0 0 33.333%;
				max-width: 33.333%;
			}
	
		}

		&__cols {
			padding: 0;
		}

	}

	@media screen and (min-width:70em) {

		h2 {
			@include heading_size3();
		}
	
		h3 {
			@include heading_size2();
		}

	}

	@media screen and (min-width:85em) {
		padding: rem(70) 0;
	}

	&--no-gap {
		padding-bottom: 0;
	}

}