// Prefix module classes with m-

.m-flexible-links {
	padding: rem(30) rem(20);

	a {
		@include font_semibold();
		@include heading_size1();
		background: $primary;
		color: white;
		display: block;
		padding: rem(30) rem(70) rem(30) rem(30);
		position: relative;
		text-decoration: none;

		small {
			@include body_size1();
			@include font_regular();	
		}

	}

	li {
		
		&:not(:last-child) {
			margin-bottom: rem(20);
		}

	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	&__icon {
		background: $accent_light;
		color: $primary;
		height: rem(50);
		padding: rem(10);
		position: absolute;
		right: 0;
		bottom: 0;
		width: rem(50);

		svg {
			stroke: currentColor;
		}

	}

	&--white {
		background: white;
	}



	
	@media screen and (min-width:50em) {
		padding: rem(50) rem(30);

		a {
			@include heading_size3();
			padding: rem(40) rem(80) rem(40) rem(40);

			small {
				@include body_size3();
			}

		}

		&__icon {
			height: rem(60);
			width: rem(60);
		}

	}

	@media screen and (min-width:85em) {
		padding: rem(70) 0;

		&__icon {
			bottom: auto;
			right: -30px;
			top: calc(50% - 30px);
		}

	}

	&--no-gap {
		padding-bottom: 0;
	}

}