// Prefix module classes with m-

.m-modal {
    color: $primary;
    padding: rem(20);
    position: absolute;
    top: rem(114);
    width: 100%;

    h2 {
        @include font_semibold();
        @include heading_size3();
        color: $accent_light;
        margin: 0;
    }

    p {
        @include body_size2();
        margin: 0;

        &:not(:last-child) {
            margin-bottom: rem(20);
        }

    }

    &, &__close-bg {
        display: none;
        left: 0;
        transition: all .25s ease-in-out;
        z-index: 15000;
    }

    &__close-bg {
        @include background-opacity($primary,0.95);
        bottom: 0;
        position: fixed;
        right: 0;
        top: 0;
    }

    &__col {

        &--minor {
            margin-bottom: rem(25);
        }

    }

    &__cols {
        position: relative;
    }

    &__info {

        p {
            @include body_size3();
            color: white;
        }

    }

    &__position {
        
        p {
            @include body_size3();
            color: $tertiary;
            margin: 0 0 rem(40) 0;
        }

    }

    &-toggle {
        $self: &;
        background: none;
        border: none;
        cursor: pointer;
        height: 2rem;
        padding: 0;
        position: relative;
        width: 2rem;

        &:focus, &:hover {

            #{$self}-line {
                background: $accent_light;
            }

        }

        &-container {
            background: $primary;
            position: absolute;
            right: 0;
            top: 0;
        }

        &-line {
            background: white;
            display: block;
            height: 2px;
            left: 0;
            opacity: 1;
            position: absolute;
            top: calc(50% - 1px);
            transform: rotate(0deg);
            transition: all .25s ease-in-out;
            width: 100%;

            &:nth-child(1), &:nth-child(4) {
                opacity: 0;
            }

            &:nth-child(2) {
                transform: rotate(-45deg);
            }

            &:nth-child(3) {
                transform: rotate(45deg);
            }

        }

    }

    &--isactive, &__close-bg--isactive {
        animation: {
            duration: 0.3s;
            fill-mode: forwards;
            iteration-count: 1;
            name: fadein;
        }
        display: block;
    }

    @media screen and (min-width:35em) {

        &__col {

            &--major {
                flex: 0 0 66.666%;
                max-width: 66.666%;
                padding-left: rem(25);
            }
    
            &--minor {
                flex: 0 0 33.333%;
                margin: 0;
                max-width: 33.333%;
            }
    
        }

        &__cols {
            display: flex;
        }

        &-toggle {

            &-container {
                background: none;
            }

        }

    }

    @media screen and (min-width:50em) {
        left: calc(50% - 350px);
        top: 25vh;
        width: rem(700);

        &__col {

            &--major {
                padding-left: rem(50);
            }
    
        }

        &__cols {
            padding: rem(50);
        }

    }

    @media screen and (min-width:70em) {
        left: calc(50% - 500px);
        width: rem(1000);
    }

}