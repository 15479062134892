// Prefix module classes with m-

.m-front-page-twitter {
  background: white;
  padding: rem(30) rem(20);

  &__logo {
    a {
      color: $primary;
      display: block;
      height: rem(40);
      margin-bottom: rem(12);
      transition: color 0.3s ease;
      width: rem(40);

      svg {
        height: auto;
        fill: currentColor;
        width: 100%;
      }

      &:focus,
      &:hover {
        color: $accent_light;
      }
    }
  }

  @media screen and (min-width: 50em) {
    padding: rem(70) rem(20);
  }

  @media screen and (min-width: 75em) {
    padding: rem(70) 0;
  }
}
