// Prefix layout classes with l-

.l-page {
    margin: 0 auto;

    & {
        max-width: rem(1440);
    }

    &-l {
        max-width: rem(1280);
    }

    &-m {
        max-width: rem(1120);
    }

    &-s {
        max-width: rem(880);
    }

}