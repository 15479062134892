// Prefix module classes with m-

// http://embedresponsively.com

.m-embed {
    height: 0;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    embed, iframe, object {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &--16by9 {
        padding-bottom: 56.25%;
    }

    &--4by3 {
        padding-bottom: 75%;
    }

}