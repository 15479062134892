// Mixins
@mixin full-table {

	.m-table {

        &-xcol {
            td, th {
                display: table-cell;
            }
        }

        &--3col {
            td, th {
                width: 33.333%;
            }
        }
    
        &--4col {
            td, th {
                width: 25%;
            }
        }
    
        &--5col {
            td, th {
                width: 20%;
            }
        }
    
        &--6col {
            td, th {
                width: 16.666%;
            }
        }

        &--7col {
            td, th {
                width: 14.285%;
            }
        }

        &--8col {
            td, th {
                width: 12.5%;
            }
        }

        &--9col {
            td, th {
                width: 11.111%;
            }
        }

        &--10col {
            td, th {
                width: 10%;
            }
        }

    }

}

// Prefix module classes with m-

.m-table-xcol {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: rem(30);
    }

	td, th {
        color: white;
        line-height: 1;
		padding: rem(10) 0;
		text-align: left;
		vertical-align: top;
		width: 50%;

		a {
            color: white;
        }

	}

	th {
        width: 50%;
    }

    thead {

        th {
            @include font_semibold();
        }

    }

    tr {
        border-bottom: 1px solid white;
    }

}

.m-table {

    &--3col, &--4col, &--5col, &--6col, &--7col, &--8col, &--9col, &--10col {

        td, th {
            display: none;
        }

        th[scope="col"]:first-child, th[scope="row"], .m-table-xcol__col--isactive {
            animation: {
                duration: 0.3s;
                fill-mode: forwards;
                iteration-count: 1;
                name: fadein;
            }
            display: table-cell;
        }

    }

}

.m-table-xcol-toggle {
	font-size: em(14);
	text-align: right;

	button {
        @include font_semibold();
        background: $primary;
		border: 1px solid white;
        border-radius: 0;
        color: white;
        cursor: pointer;
		display: inline-block;
		margin-bottom: rem(10);
		margin-left: rem(10);
        padding: rem(5) rem(20);
        transition: all 0.3s ease;

		&:focus, &:hover, &.m-table-xcol-toggle__col--isactive {
			background: white;
			color: $primary;
		}

	}

}

@media screen and (min-width: 75em) {

	@include full-table;

	.m-table-xcol-toggle {
        display: none;
    }

}