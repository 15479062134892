// Prefix module classes with m-

.m-hero-front {

	&__featured {
		background: $neutral_30;
		padding: rem(30) rem(20) 0 rem(20);

		a {
			color: $primary;
			text-decoration: none;
		}

		h2, p {
			margin: 0 0 rem(20) 0;
		}

		h2 {
			@include body_size3();
			@include font_semibold();
		}

		hr {
			border: none;
			border-top: 4px solid $primary;
			margin: 0;
			text-align: left;
			width: rem(40);
		}

		li {
			display: flex;
			margin: 0 -10px;
			padding-bottom: rem(30);

			div {
				padding: 0 rem(10);

				&:first-child {
					flex: 0 0 30%;
					max-width: 30%;
				}

				&:last-child {
					flex: 0 0 70%;
					max-width: 70%;
				}

			}

		}

		p {
			@include body_size2();
		}

		ul {
			margin: 0;
			list-style-type: none;
			padding: 0;
		}

	}

	&__heading {
		background: {
			position: top right;
			repeat: no-repeat;
			size: calc(50% - 10px);
		}
		color: white;
		padding: rem(50) rem(20);

		a {
			@include font_semibold();
			color: $primary;
			background: $accent_light;
			display: inline-block;
			padding: rem(15) rem(30);
			text-decoration: none;
		}

		h1 {
			@include body_size3();
			color: white;
			margin: 0 0 rem(30) 0;
		}

	}

	&__strapline {
		margin: 0 0 rem(20) 0;
		width: calc(50% - 10px);
	}

	@media screen and (min-width:40em) {

		&__featured {

			li {

				div {

					&:first-child {
						flex: 0 0 20%;
						max-width: 20%;
					}
		
					&:last-child {
						flex: 0 0 80%;
						max-width: 80%;
					}
		
				}

			}

		}

		&__heading {
			background: {
				size: calc(50%);
			}
		}

		&__inner {
			width: 50%;
		}

		&__strapline {
			margin: 0 0 rem(30) 0;
			width: rem(200);
		}

	}

	@media screen and (min-width:50em) {

		&__featured {
			padding: rem(50) rem(30);

			li {
				flex: 0 0 33.333%;
				max-width: 33.333%;
				padding: 0 rem(10);

				div {

					&:first-child {
						flex: 0 0 30%;
						max-width: 30%;
					}
		
					&:last-child {
						flex: 0 0 70%;
						max-width: 70%;
					}
		
				}

			}

			ul {
				display: flex;
				flex-flow: row wrap;
				margin: 0 -10px;
			}

		}

		&__heading {
			padding: rem(50) rem(30);
		}

		&__strapline {
			width: rem(250);
		}

	}

	@media screen and (min-width:70em) {

		&__heading {
			background: {
				size: contain;
			}
		}

		&__inner {
			width: 33.333%;
		}

	}

	@media screen and (min-width:85em) {

		&__featured {
			padding: rem(50) 0;
		}

		&__heading {
			padding: rem(70) 0;
		}

	}

}