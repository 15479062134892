// Prefix module classes with m-

.m-hero-default {
	@include font_semibold();

	h1 {
		@include heading_size3();
		color: white;
		margin: 0;
	}

	&__heading {
		background: {
			position: top right;
			repeat: no-repeat;
			size: contain;
		}
		padding: rem(50) rem(20);
	}

	&__links {
		@include font_semibold();
		background: $accent_light;
		padding: rem(15) rem(20);

		a {
			@include body_size1();
			color: white;
			line-height: 1.75;
			text-decoration: none;
			transition: color 0.3s ease;

			&:focus, &:hover, strong {
				color: $primary;
			}

		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

	}

	@media screen and (min-width:35em) {

		&__links {

			a {
				line-height: 1.5;
			}

			li {
				display: inline-block;
				margin-right: rem(20);
			}

		}

	}

	@media screen and (min-width:50em) {

		&__heading {
			padding: rem(70) rem(30);
		}

		&__links {
			padding: rem(15) rem(30);
		}

	}

	@media screen and (min-width:60em) {

		&__links {

			a {
				@include body_size2();
			}

		}

	}

	@media screen and (min-width:70em) {

		h1 {
			@include heading_size4();
		}

		&__heading {
			padding: rem(100) 0;
		}

		&__links {
			padding: rem(15) 0;

			a {
				@include body_size3();
			}

			li {
				margin-right: rem(30);
			}

		}

	}

}