// Prefix module classes with m-

.m-team {
	padding: 0 rem(20);

	a {
		background: $primary;
		display: block;
		position: relative;
	}

	h3 {
		@include font_semibold();
		@include heading_size2();
		color: white;
		margin: 0 0 rem(10) 0;
	}

	p {
		@include body_size3();
		color: $tertiary;
		margin: 0;
	}

	img {
		opacity: 0.3;
	}

	&__container {
		padding-top: rem(30);
	}

	&__info {
		padding: 0 rem(20);
		position: absolute;
		left: 0;
		top: 25%;
		width: 100%;
	}

	&__item {
		padding-bottom: rem(20);
	}

	@media screen and (min-width:40em) {

		&__item {
			flex: 0 0 50%;
			margin-bottom: rem(20);
			max-width: 50%;
			padding: 0 rem(10);
		}
	
		&__items {
			display: flex;
			flex-flow: row wrap;
			margin: 0 -10px;
		}

	}

	@media screen and (min-width:50em) {
		padding: 0 rem(30);

		&__container {
			padding-top: rem(50);
		}

		&__item {
			flex: 0 0 33.333%;
			max-width: 33.333%;
		}

	}

	@media screen and (min-width:70em) {
		padding: 0;

		&__container {
			padding-top: rem(70);
		}

	}

}