// Prefix module classes with m-

.m-hero-post {
	background: white;
	
	h1 {
		@include font_semibold();
		@include heading_size3();
		color: $primary;
		margin: 0 0 rem(20) 0;
	}

	hr {
		border: none;
		border-top: 1px solid $neutral_50;
		margin: 0;
	}

	&__author {
		color: $secondary;
		margin: 0;
	}

	&__date {
		color: $neutral_70;
		margin: 0 0 rem(20) 0;
	}

	&__heading {
		margin-top: -100px;
		padding: 0 rem(20);
	}

	&__image {
		background: {
			repeat: no-repeat;
			position: center;
			size: cover;
		}
		height: rem(300);
	}

	&__inner {
		background: white;
		padding: rem(30) rem(20);
	}

	@media screen and (min-width:50em) {

		&__heading {
			padding: 0 rem(30);
		}

		&__image {
			height: rem(450);
		}

		&__inner {
			padding: rem(50) rem(30);
		}

	}

	@media screen and (min-width:70em) {

		h1 {
			@include heading_size4();
		}

	}

}