// Prefix module classes with m-

.m-flexible-logos {
	background: $neutral_30;
	padding: rem(30) rem(20);

	h2 {
		@include body_size1();
		letter-spacing: 0.1em;
		margin: 0 0 rem(20) 0;
		text-transform: uppercase;
	}

	&__slide {
		padding: rem(10);
	}

	&__slides {
		margin: 0 -10px;

		.slick-track {
			align-items: center;
			display: flex;
		}

	}

	.slick {

		&-arrow {

			&-container {
				bottom: 0;
				display: block;
				position: absolute;

				button {
					background: {
						color: transparent;
						image: 
						url("data:image/svg+xml,%3Csvg viewBox='0 0 100 50' width='100' height='50' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M93.285572 22.8392924L74.0693052 8.0474794v29.58886204L93.285572 22.8392924z' fill-rule='nonzero'/%3E%3Cpath d='M93.285572 25.1719233l-19.2162668-14.791813v29.58886204L93.285572 25.1719233z' fill='#{encodecolor($primary)}' stroke='#{encodecolor($primary)}' stroke-width='7.8540600000000005'/%3E%3Cpath d='M78.33384716 25.46940624H.8980516' fill='#{encodecolor($primary)}' stroke='#{encodecolor($primary)}' stroke-width='8.0111412' stroke-linejoin='round' stroke-miterlimit='1.5'/%3E%3C/svg%3E");
						position: top left;
						size: 100%;
					}
					border: none;
					cursor: pointer;
					display: block;
					font-size: 0;
					height: rem(18);
					line-height: 0;
					margin: 0;
					padding: 0;
					width: rem(36);
				}

				&-next {
					left: rem(60);
				}

				&-prev {
					left: rem(10);
					transform: rotate(180deg);
				}

			}

		}

		&-slider {
			padding-bottom: rem(40);
		}

	}

	@media screen and (min-width:50em) {
		padding: rem(50) rem(30);
	}

	@media screen and (min-width:75em) {
		padding: rem(70) 0;
	}

}