// Prefix module classes with m-

.m-flexible-accordions {
    $self: &;
    padding: rem(30) rem(20);

    h2 {
		@include body_size1();
		letter-spacing: 0.1em;
		margin: 0 0 rem(20) 0;
		text-transform: uppercase;
	}

    &__content {
        @include body_size2();
        display: none;
        padding: rem(30) 0;

        a {
            color: $accent_light;
            text-decoration: none;
        }

        li {
            margin: 0;
    
            &:not(:last-child) {
                margin-bottom: rem(12);
            }
    
        }

        ol, p, ul {
            margin: 0;
    
            &:not(:last-child) {
                margin-bottom: rem(30);
            }
    
        }
    
        ol, ul {
            padding-left: rem(20);
        }

    }

    &__heading {
        @include font_regular();
        @include heading_size1();
        border-top: 1px solid tint(black,60%);
        display: block;
        line-height: 1.2;
        margin: 0;
        padding: rem(20) rem(60) rem(20) 0;
        position: relative;

        a {
            color: black;
            display: block;
            text-decoration: none;
            transition: all 0.2s ease;
        }

    }

    &__icon {
        background: {
            image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M5.42162 26.73132l44.60592 44.60592 44.60592-44.60592' fill='none' stroke='%23000' stroke-width='14.86864'/%3E%3C/svg%3E");
            position: center;
            repeat: no-repeat;
            size: rem(20);
        }
        height: rem(20);
        position: absolute;
        right: 0;
        top: rem(20);
        transition: all 0.3s ease;
        width: rem(20);
    }

    &__intro {
        
        p {
            @include font_semibold();
            @include heading_size2();
            margin: 0 0 rem(30) 0;
        }

    }

    &__item {

        &:not(:last-child) {
            margin-bottom: rem(25);
        }

        &--isactive {

            #{$self}__content {
                animation: {
                    duration: 0.3s;
                    fill-mode: forwards;
                    iteration-count: 1;
                    name: fadein;
                }
                display: block;
            }

            #{$self}__icon {
                transform: rotate(180deg);
            }
    
        }

    }

    &__links {
    
        li {
            display: inline-block;
            margin-right: rem(20);
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

    }

    @media screen and (min-width:50em) {
        padding: rem(50) rem(30);
    }

    @media screen and (min-width:70em) {
        padding: rem(70) 0;
    }

}