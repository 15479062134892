// Prefix module classes with m-

.m-members {

	&__cta {
		background: $primary;

		a {
			display: inline-block;
			text-decoration: none;
		}

		h2 {
			@include font_semibold();
			@include heading_size3();
			color: $accent_light;
			margin: 0 0 rem(20) 0;
		}

		p {
			@include body_size3();
			color: white;
			margin: 0;
		}

		&-heading {
			background: {
				position: top right;
				repeat: no-repeat;
				size: contain;
			}
			padding: rem(50) rem(20);
		}

	}

	@media screen and (min-width:50em) {

		&__cta {

			&-heading {
				padding: rem(100) rem(30);
			}

		}

	}

	@media screen and (min-width:90em) {

		&__cta {

			&-heading {
				padding: rem(100) 0;
			}

		}

	}

}