// Prefix module classes with m-

.m-header {
  background: white;
  padding: rem(30) 0;

  &__logo {
    height: rem(40);
    margin-left: rem(20);
    position: relative;
    width: rem(120);
    z-index: 12000;

    &--isactive {
      svg {
        g {
          fill: white;
        }
      }
    }

    &--lottie {
      &,
      a,
      .m-header__lottie > div {
        aspect-ratio: 1328 / 392;
        display: block;
        height: auto;
        width: rem(200);
      }

      .m-header__lottie {
        > div:last-child {
          display: none;
          visibility: hidden;
        }
      }
    }

    &--lottie.m-header__logo--isactive {
      .m-header__lottie {
        > div:first-child {
          display: none;
          visibility: hidden;
        }

        > div:last-child {
          display: block;
          visibility: visible;
        }
      }
    }
  }

  &__menu {
    &-mobile {
      $self: &;
      background: $primary;
      display: none;
      left: 0;
      padding: rem(150) rem(20) rem(30) rem(20);
      position: absolute;
      top: -100%;
      width: 100%;
      z-index: 10100;

      form {
        margin-bottom: rem(30);
        max-width: rem(400);

        button {
          display: none;
        }

        input[type="search"] {
          @include body_size2();
          background: {
            color: white;
            image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke='#{encodecolor($primary)}' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M44.21413 82.84907c21.35806994 0 38.67464-17.31657006 38.67464-38.67464 0-21.35806994-17.31657006-38.67464-38.67464-38.67464-21.35806994 0-38.67464 17.31657006-38.67464 38.67464 0 21.35806994 17.31657006 38.67464 38.67464 38.67464z' fill='none' stroke-width='9.66866'/%3E%3Cpath d='M92.55743 92.51773L71.5280945 71.4883945' fill='none' stroke-width='9.66866' stroke-linecap='square' stroke-linejoin='miter'/%3E%3C/svg%3E");
            position: 10px center;
            repeat: no-repeat;
            size: rem(20);
          }
          border: none;
          display: inline-block;
          height: rem(50);
          line-height: rem(40);
          padding: rem(5) rem(10) rem(5) rem(40);
          width: 100%;

          &::placeholder {
            color: $neutral_50;
          }
        }
      }

      &-close {
        @include background-opacity($primary, 0.9);
        bottom: 0;
        display: none;
        left: 0;
        position: fixed;
        right: 0;
        transition: all 0.25s ease-in-out;
        top: 0;
        z-index: 10000;

        &--isactive {
          animation: {
            duration: 0.3s;
            fill-mode: forwards;
            iteration-count: 1;
            name: fadein;
          }
          display: block;
        }

        &:focus,
        &:hover {
          @include background-opacity($accent_light, 0.5);
        }
      }

      &-signup {
        margin-top: rem(30);
        max-width: rem(400);

        h2,
        p {
          margin: 0 0 rem(10) 0;
        }

        h2 {
          a {
            @include font_semibold();
            @include heading_size1();
            background: {
              image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M13.73179 14.20602h72.52992c4.986432 0 9.06624 4.079808 9.06624 9.06624V77.6697c0 4.986432-4.079808 9.06624-9.06624 9.06624H13.73179c-4.986432 0-9.06624-4.079808-9.06624-9.06624V23.27226c0-4.986432 4.079808-9.06624 9.06624-9.06624z' fill='none' stroke='#{encodecolor($accent_light)}' stroke-width='9.06624'/%3E%3Cpath d='M95.32795 23.27226L49.99675 55.0041 4.66555 23.27226' fill='none' stroke='#{encodecolor($accent_light)}' stroke-width='9.06624'/%3E%3C/svg%3E");
              position: top left;
              repeat: no-repeat;
              size: rem(30);
            }
            color: $accent_light;
            display: block;
            padding-top: rem(35);
            text-decoration: none;
          }
        }

        p {
          @include body_size2();
          color: white;
        }

        &-cta {
          background: {
            color: transparent;
            image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 50' width='100' height='50' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M93.285572 22.8392924L74.0693052 8.0474794v29.58886204L93.285572 22.8392924z' fill-rule='nonzero'/%3E%3Cpath d='M93.285572 25.1719233l-19.2162668-14.791813v29.58886204L93.285572 25.1719233z' fill='#{encodecolor($accent_light)}' stroke='#{encodecolor($accent_light)}' stroke-width='7.8540600000000005'/%3E%3Cpath d='M78.33384716 25.46940624H.8980516' fill='#{encodecolor($accent_light)}' stroke='#{encodecolor($accent_light)}' stroke-width='8.0111412' stroke-linejoin='round' stroke-miterlimit='1.5'/%3E%3C/svg%3E");
            position: top left;
            size: 100%;
          }
          display: block;
          font-size: 0;
          height: rem(18);
          width: rem(36);
        }
      }

      &--isactive {
        animation: {
          duration: 0.5s;
          fill-mode: forwards;
          iteration-count: 1;
          name: slidein;
        }
        display: block;

        ul {
          animation: {
            duration: 1.5s;
            fill-mode: forwards;
            iteration-count: 1;
            name: fadein;
          }
          display: block;
        }
      }
    }
  }

  &__navigation {
    @include clearfix();
    position: relative;
  }

  &__primary,
  &__secondary {
    display: none;
  }

  &__search {
    @include clearfix();

    button,
    form {
      float: left;
    }

    button {
      background: transparent;
      border: none;
      color: $primary;
      cursor: pointer;
      display: block;
      height: rem(20);
      padding: 0;
      transition: color 0.3s ease;
      width: rem(20);

      &:focus,
      &:hover {
        color: $accent_light;
      }

      svg {
        stroke: currentColor;
      }
    }

    form,
    input[type="search"] {
      line-height: rem(19);
    }

    form {
      margin-right: rem(8);
    }

    input[type="search"] {
      border: none;
      border-bottom: 1px solid $neutral_50;
      color: $primary;
      font-size: rem(16);
      padding: 0;
      transition: all 0.3s ease;
      width: 0;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $neutral_50;
      }
    }

    &--isactive {
      input[type="search"] {
        width: rem(200);
      }
    }
  }

  &__skip {
    @include body_size2();
    background: $primary;
    color: white;
    display: block;
    left: 0;
    padding: 0 rem(16);
    position: absolute;
    text-decoration: none;
    top: -100px;
    width: 100%;
    z-index: 8000;

    &:focus {
      outline: none;
      top: 0;
    }
  }

  &__toggle {
    $self: &;
    background: none;
    border: none;
    cursor: pointer;
    height: rem(24);
    padding: 0;
    position: relative;
    width: rem(24);
    z-index: 12000;

    &:focus,
    &:hover {
      #{$self}-line {
      }
    }

    &-container {
      position: absolute;
      right: rem(20);
      top: rem(16);
    }

    &-line {
      background: $primary;
      display: block;
      height: 2px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      transition: all 0.25s ease-in-out;
      width: 100%;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: calc(50% - 1px);
      }

      &:nth-child(4) {
        bottom: 0;
      }
    }

    &--isactive {
      .m-header__toggle-line {
        background: white;

        &:nth-child(1),
        &:nth-child(4) {
          opacity: 0;
        }

        &:nth-child(2) {
          transform: rotate(-45deg);
        }

        &:nth-child(3) {
          transform: rotate(45deg);
        }
      }
    }
  }

  @media screen and (min-width: 50em) {
    &__logo {
      margin-left: rem(30);
    }

    &__menu {
      &-mobile {
        padding: rem(150) rem(30) rem(50) rem(30);

        &-signup {
          h2 {
            a {
              @include heading_size2();
            }
          }

          p {
            @include body_size3();
          }
        }
      }
    }

    &__toggle {
      &-container {
        right: rem(30);
      }
    }
  }

  @media screen and (min-width: 70em) {
    .m-header__lottie {
      > div:first-child {
        display: block !important;
        visibility: visible !important;
      }

      > div:last-child {
        display: none !important;
        visibility: hidden !important;
      }
    }

    &__menu {
      &-mobile {
        &-close {
          &--isactive {
            display: none;
          }
        }

        &,
        &--isactive {
          display: none;
        }
      }
    }

    &__logo {
      bottom: 0;
      left: 0;
      position: absolute;

      svg {
        g {
          fill: $primary !important;
        }
      }
    }

    &__primary,
    &__secondary {
      display: block;
      float: right;
      margin-right: rem(30);
    }

    &__primary {
      clear: right;
    }

    &__secondary {
      div {
        &:first-child {
          float: left;
        }

        &:last-child {
          float: right;
        }
      }
    }

    &__toggle {
      &-container {
        display: none;
      }
    }
  }

  @media screen and (min-width: 75em) {
    &__logo {
      &--lottie {
        &,
        a,
        .m-header__lottie > div {
          width: rem(260);
        }
      }
    }
  }

  @media screen and (min-width: 85em) {
    &__logo {
      margin-left: 0;
    }

    &__primary,
    &__secondary {
      margin-right: 0;
    }
  }
}
