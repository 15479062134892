// Prefix module classes with m-

#ctf {
  .ctf-corner-logo {
    display: none;
  }

  .ctf-item {
    @include body_size2();
    border: none;
    padding: 0;

    a {
      color: $accent_light;
      text-decoration: none;
    }
  }

  .ctf-screename-sep {
    display: none;
  }

  .ctf-tweet-items {
    &:not(.slick-initialized) {
      .ctf-item:not(:first-child) {
        display: none;
      }
    }

    .slick-track {
      padding-bottom: rem(70);
    }
  }

  .ctf-with-logo {
    .ctf-author-box {
      margin-bottom: rem(20);
    }
  }

  .slick {
    &-dots {
      bottom: rem(10);
      left: 0;
      list-style-type: none;
      margin: 0;
      padding-left: 0;
      position: absolute;
      width: 100%;

      li {
        display: inline-block;

        button {
          background: $accent_light;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          display: block;
          font-size: 0;
          height: 10px !important;
          line-height: 10px !important;
          padding: 0;
          transition: all 0.3s ease;
          width: 10px !important;
        }

        &:not(:last-child) {
          margin-right: rem(14);
        }

        &.slick-active {
          button {
            background: $primary;
            transform: scale(1.5);
          }
        }
      }
    }
  }

  @media screen and (min-width: 50em) {
    .ctf-item {
      @include heading_size1();
    }
  }
}
