// Prefix module classes with m-

.m-flexible-join {
  &__col {
    &--major {
    }

    &--minor {
      border-bottom: 1px solid white;
      margin-bottom: rem(30);
    }
  }

  &__cta {
    @include font_semibold();
    border: 1px solid white;
    color: white;
    display: inline-block;
    padding: rem(10) rem(40);
    text-decoration: none;
  }

  &__form {
    background: $neutral_30;
    display: none;

    &--isactive {
      animation: {
        duration: 0.7s;
        fill-mode: forwards;
        iteration-count: 1;
        name: fadein;
      }
      display: block;
    }
  }

  &__heading {
    padding-bottom: rem(30);

    h2 {
      @include heading_size2();
      @include font_semibold();
      margin: 0;
      text-align: center;
    }
  }

  &__info {
    display: none;

    a {
      color: $accent_light;
    }

    h3,
    p,
    ul {
      margin: 0 0 rem(30) 0;
    }

    h3 {
      @include heading_size3();

      small {
        @include heading_size1();
      }
    }

    p,
    ul {
      @include body_size2();
    }

    li {
      background: {
        image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M89.20407916 23.24205L35.20735748 77.23140916 10.66807832 52.69213' fill='none' stroke='%23fff' stroke-width='14.72504'/%3E%3C/svg%3E");
        position: left top;
        repeat: no-repeat;
        size: rem(16);
      }
      margin-bottom: rem(10);
      padding-left: rem(30);
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    &--isactive {
      animation: {
        duration: 0.7s;
        fill-mode: forwards;
        iteration-count: 1;
        name: fadein;
      }
      display: block;
    }
  }

  &__plans {
    background: $primary;
    color: white;
    padding: rem(30) rem(20);
  }

  &__toggle {
    @include heading_size1();
    border-bottom: 2px solid transparent;
    color: white;
    display: inline-block;
    padding-bottom: rem(2);
    text-decoration: none;
    transition: border-color 0.3s ease;

    &--isactive {
      border-color: $accent_light;
    }
  }

  &__toggles {
    li {
      margin-bottom: rem(15);

      &:last-child {
        margin-bottom: rem(30);
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  @media screen and (min-width: 40em) {
    &__info {
      li {
        flex: 0 0 50%;
        max-width: 50%;

        &:nth-child(odd) {
          padding-right: rem(20);
        }
      }

      ul {
        display: flex;
        flex-flow: row wrap;
      }
    }
  }

  @media screen and (min-width: 50em) {
    &__col {
      padding: 0 rem(30);

      &--major {
        border-left: 1px solid white;
        flex: 0 0 70%;
        max-width: 70%;
      }

      &--minor {
        border: none;
        flex: 0 0 30%;
        max-width: 30%;
      }
    }

    &__cols {
      display: flex;
    }

    &__heading {
      padding-bottom: rem(70);

      h2 {
        @include heading_size4();
      }
    }

    &__plans {
      padding: rem(50) 0;
    }

    &__toggles {
      li {
        margin-bottom: rem(20);
      }
    }
  }

  @media screen and (min-width: 70em) {
    &__col {
      padding: 0 rem(70);
    }

    &__plans {
      padding: rem(70) 0;
    }

    &__toggle {
      @include heading_size2();
      border-bottom-width: 4px;
    }
  }
}
