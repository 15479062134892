// Functions

// https://gist.github.com/certainlyakey/e9c0d8f5c87ff47e3d5b

@function encodecolor($string) {
	@if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
	@return $string;
}

// https://css-tricks.com/snippets/sass/px-to-em-functions

$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
	@return #{$pixels/$context}rem;
}

// https://css-tricks.com/snippets/sass/tint-shade-functions

@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

// Mixins

// http://stackoverflow.com/questions/10929458/sass-converting-hex-to-rgba-for-background-opacity

@mixin background-opacity($color, $opacity: 0.5) {
	background: $color; // Fallback
	background: rgba($color, $opacity);
}

@mixin clearfix() {
	&::after,
	&::before {
		content: " ";
		display: table;
	}

	&::after {
		clear: both;
	}
}

/* Fonts */

@mixin font_regular() {
	font-family: 'Axiforma-Regular', arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin font_semibold() {
	font-family: 'Axiforma-SemiBold', arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

// Typography

@mixin body_size1() {
	font-size: rem(14);
	line-height: 1.5;
}

@mixin body_size2() {
	font-size: rem(16);
	line-height: 1.5;
}

@mixin body_size3() {
	font-size: rem(18);
	line-height: 1.5;
}

@mixin heading_size1() {
	font-size: rem(20);
	line-height: 1.3;
}

@mixin heading_size2() {
	font-size: rem(24);
	line-height: 1.3;
}

@mixin heading_size3() {
	font-size: rem(36);
	line-height: 1.3;
}

@mixin heading_size4() {
	font-size: rem(48);
	line-height: 1.3;
}

@mixin heading_size5() {
	font-size: rem(72);
	line-height: 1.3;
}