// Prefix module classes with m-

.m-menu__secondary {
    margin-right: rem(20);

	a {
        @include font_semibold();
        color: $primary;
        font-size: rem(16);
		text-decoration: none;
		transition: color 0.3s ease;

		&:focus, &:hover {
			color: $accent_light;
		}

    }

    li {
        margin-right: rem(12);
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        > .menu {

            &-item {
                display: inline-block;
                line-height: rem(25);
            }

        }

    }

    .current-menu-item, .current-menu-parent, .current-page-ancestor, .current_page_parent {

        > a {
            color: $accent_light;
        }

    }

    &-mobile {
        margin-bottom: rem(30);

        .menu {
            list-style-type: none;
            margin: 0;
            padding: 0;

            > .menu-item {

                &:not(:last-child) {
                    margin-bottom: rem(5);
                }

                a {
                    @include body_size2();
                    color: white;
                    text-decoration: none;
                    transition: color 0.3s ease;

                    &:focus, &:hover {
                        color: $accent_light;
                    }

                }

            }

            .current-menu-item, .current-menu-parent, .current-page-ancestor, .current_page_parent {

                > a {
                    color: $accent_light;
                }
        
            }

        }

        @media screen and (min-width:50em) {

            .menu {
    
                > .menu-item {
    
                    a {
                        @include body_size3();
                    }

                }

            }

        }

    }

}