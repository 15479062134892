// Prefix module classes with m-

.m-social {

    a {

        &:focus, &:hover {
            color: $accent_light;
        }

    }

	li {
        display: inline-block;

        &:not(:last-child) {
            margin-right: rem(16);
        }

    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__icon {
        display: block;
        transition: color 0.3s ease;

        svg {
            height: rem(20);
            fill: currentColor;
            width: rem(20);
        }

    }

    &--business, &--header {

        a {
            color: $primary;
        }

    }

    &--business {
        margin: 0 0 rem(10) 0;
    }

    &--footer, &--mobile {

        a {
            color: white;
        }

    }

    @media screen and (min-width:50em) {

        &--business {
            margin-left: rem(10);
        }

    }

}