$res_path: "../res";
/*
Example usage:
background-image: url(#{$res_path}/img/logo.svg);
*/

// Colours

$accent_dark: #169FDB;
$accent_light: #09B2AF;
$neutral_90: #1A1A1F;
$neutral_70: #5F5F62;
$neutral_50: #8C8C8F;
$neutral_30: #D1D1D2;
$neutral_10: #F8F8F8;
$primary: #221C35;
$secondary: #E84427;
$tertiary: #DBC362;

// Fonts

@import url("//hello.myfonts.net/count/3e6692");

/*
@font-face {
	font-family: "Axiforma-BookItalic";
	src: url(#{$res_path}/fonts/AxiformaBookItalic/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaBookItalic/font.woff) format('woff');
}
@font-face {
	font-family: "Axiforma-Book";
	src: url(#{$res_path}/fonts/AxiformaBook/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaBook/font.woff) format('woff');
}
@font-face {
	font-family: "Axiforma-Bold";
	src: url(#{$res_path}/fonts/AxiformaBold/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaBold/font.woff) format('woff');
}
@font-face {
	font-family: "Axiforma-Italic";
	src: url(#{$res_path}/fonts/AxiformaItalic/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaItalic/font.woff) format('woff');
}
@font-face {
	font-family: "Axiforma-BoldItalic";
	src: url(#{$res_path}/fonts/AxiformaBoldItalic/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaBoldItalic/font.woff) format('woff');
}
*/
@font-face {
	font-family: "Axiforma-Regular";
	src: url(#{$res_path}/fonts/AxiformaRegular/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaRegular/font.woff) format('woff');
}
/*
@font-face {
	font-family: "Axiforma-SemiBoldItalic";
	src: url(#{$res_path}/fonts/AxiformaSemiBoldItalic/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaSemiBoldItalic/font.woff) format('woff');
}
@font-face {
	font-family: "Axiforma-MediumItalic";
	src: url(#{$res_path}/fonts/AxiformaMediumItalic/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaMediumItalic/font.woff) format('woff');
}
@font-face {
	font-family: "Axiforma-Medium";
	src: url(#{$res_path}/fonts/AxiformaMedium/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaMedium/font.woff) format('woff');
}
*/
@font-face {
	font-family: "Axiforma-SemiBold";
	src: url(#{$res_path}/fonts/AxiformaSemiBold/font.woff2) format('woff2'), url(#{$res_path}/fonts/AxiformaSemiBold/font.woff) format('woff');
}