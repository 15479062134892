// Prefix module classes with m-

.m-hero-event {
	background: white;
	
	h1 {
		@include font_semibold();
		@include heading_size3();
		color: $primary;
		margin: 0 0 rem(20) 0;
	}

	hr {
		border: none;
		border-top: 1px solid $neutral_50;
		margin: 0;
	}

	&__author {
		color: $secondary;
		margin: 0;
	}

	&__date {
		color: $neutral_70;
		margin: 0 0 rem(20) 0;
	}

	&__heading {
		margin-top: -100px;
		padding: 0 rem(20);
	}

	&__image {
		background: {
			repeat: no-repeat;
			position: center;
			size: cover;
		}
		height: rem(300);
	}

	&__inner {
		background: white;
		padding: rem(30) rem(20);
	}

	&__location {

		a {
			color: $accent_dark;
			text-decoration: none;
		}

	}

	&__register {

		a {
			@include body_size3();
			display: inline-block;
			text-decoration: none;
		
			span {
				background: {
					color: transparent;
					image: 
					url("data:image/svg+xml,%3Csvg viewBox='0 0 100 50' width='100' height='50' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M93.285572 22.8392924L74.0693052 8.0474794v29.58886204L93.285572 22.8392924z' fill-rule='nonzero'/%3E%3Cpath d='M93.285572 25.1719233l-19.2162668-14.791813v29.58886204L93.285572 25.1719233z' fill='#{encodecolor($primary)}' stroke='#{encodecolor($primary)}' stroke-width='7.8540600000000005'/%3E%3Cpath d='M78.33384716 25.46940624H.8980516' fill='#{encodecolor($primary)}' stroke='#{encodecolor($primary)}' stroke-width='8.0111412' stroke-linejoin='round' stroke-miterlimit='1.5'/%3E%3C/svg%3E");
					position: center right;
					repeat: no-repeat;
					size: rem(24);
				}
				color: $primary;
				display: block;
				padding-right: rem(36);
			}

		}

	}

	@media screen and (min-width:50em) {

		&__heading {
			padding: 0 rem(30);
		}

		&__image {
			height: rem(450);
		}

		&__inner {
			padding: rem(50) rem(30);
		}

	}

	@media screen and (min-width:70em) {

		h1 {
			@include heading_size4();
		}

	}

}