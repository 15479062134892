// Prefix module classes with m-

.m-link {

    &__icon {
        @include font_semibold();
        $self: &;
        background: white;
        border: 1px solid $neutral_90;
        color: $neutral_90;
        display: inline-block;
        padding: rem(10) rem(16);
        text-decoration: none;

        &-icon, &-text {
            display: inline-block;
            vertical-align: bottom;
        }

        &-icon {
            height: rem(24);
            margin-left: rem(12);
            width: rem(24);

            svg {
				stroke: currentColor;
			}

            &--blue {
                color: $primary;
            }
    
            &--green {
                color: $accent_light;
            }
    
            &--white {
                color: white;
            }
            
        }

        &-text {
            font-size: rem(14);
            line-height: rem(24);

            &--blue {
                color: $primary;
            }
    
            &--green {
                color: $accent_light;
            }
    
            &--white {
                color: white;
            }

        }

        &--background {

            &-blue {
                background: $primary;
            }
    
            &-green {
                background: $accent_light;
            }
    
            &-white {
                background: white;
            }
    
        }
    
        &--border {
    
            &-blue {
                border-color: $primary;
            }
    
            &-green {
                border-color: $accent_light;
            }
    
            &-white {
                border-color: white;
            }
    
        }

        &:not(:last-child) {
			margin-bottom: rem(20);
		}

    }
    
}