// Prefix module classes with m-

.m-menu__policies {
  margin-top: rem(20);

  ul {
    list-style-type: none;
    margin: 0 0 rem(30) 0;
    padding: 0;
  }
}
