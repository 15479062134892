// Prefix module classes with m-

.m-filter {
	padding: rem(30) rem(20) 0 rem(20);

	button {
		display: none;
	}

	form {
		display: inline-block;
	}

	input[type="search"], select {
		@include body_size2();
		border: none;
		border-bottom: 1px solid $neutral_30;
		display: inline-block;
		margin-bottom: rem(30);
	}

	input[type="search"] {
		background: none;
		height: rem(45);
		min-width: rem(240);
		padding: rem(10) 0;

		&::placeholder {
			color: $neutral_50;
		}

	}

	select {
		-moz-appearance: window;
    	-webkit-appearance: none;
		appearance: none;
		background: {
			color: transparent;
            image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M5.42162 26.73132l44.60592 44.60592 44.60592-44.60592' fill='none' stroke='%23000' stroke-width='14.86864'/%3E%3C/svg%3E");
            position: center right;
            repeat: no-repeat;
            size: rem(16);
        }
		cursor: pointer;
		margin-right: rem(30);
		padding: rem(10) rem(30) rem(10) 0;

		&::-ms-expand {
			display: none;
		}

	}

	@media screen and (min-width:50em) {
		margin-bottom: 0;
		padding: rem(50) rem(30);
	}

	@media screen and (min-width:60em) {

		input[type="search"], select {
			@include body_size3();
		}

		input[type="search"] {
			height: rem(48);
		}

	}

	@media screen and (min-width:75em) {
		padding: rem(50) 0;
    }

}