// Prefix module classes with m-

.m-flexible-quotes {
	$self: &;
	background: $neutral_30;
	color: $neutral_90;
	padding: rem(30) rem(20);

	blockquote {
		margin: 0 0 rem(30) 0;

		p {
			@include font_semibold();
			@include heading_size2();
			margin: 0;
		}

	}

	figure {
		margin: 0;
	}

	figcaption {
		@include body_size1;

		strong {
			@include body_size3;
			@include font_semibold();
		}

	}

	&__slides {
		padding-bottom: rem(70);
	}

	.slides:not(.slick-initialized) {

		#{ $self }__slide:not(:first-child) {
			display: none;
		}
	}

    .slick {

		&-dots {
			bottom: rem(10);
			left: 0;
			list-style-type: none;
			margin: 0;
			padding-left: 0;
			position: absolute;
			width: 100%;
	
			li {
				display: inline-block;
	
				button {
					background: $neutral_50;
					border: none;
					border-radius: 50%;
					cursor: pointer;
					display: block;
					font-size: 0;
					height: 10px !important;
					line-height: 10px !important;
					padding: 0;
					transition: all .3s ease;
					width: 10px !important;
				}

				&:not(:last-child) {
					margin-right: rem(14);
				}
	
				&.slick-active {
	
					button {
						background: $neutral_90;
						transform: scale(1.5);
					}
	
				}
	
			}
	
		}

	}

	&--blue {
		background: $primary;
		color: $accent_light;

		figcaption {
	
			strong {
				color: white;
			}
	
		}

		.slick {

			&-dots {
		
				li {
		
					button {
						background: $accent_light;
					}

					&.slick-active {
		
						button {
							background: white;
						}
		
					}
		
				}
		
			}
	
		}

	}

	&--green {
		background: $accent_light;
		color: white;

		figcaption {
			color: $primary;
		}

		.slick {

			&-dots {
		
				li {
		
					button {
						background: $primary;
					}

					&.slick-active {
		
						button {
							background: white;
						}
		
					}
		
				}
		
			}
	
		}

	}

	@media screen and (min-width:50em) {
		padding: rem(50);

		blockquote {
	
			p {
				@include heading_size3();
			}
	
		}

	}

	@media screen and (min-width:75em) {
		padding: rem(70) 0 rem(60) 0;
	}

}