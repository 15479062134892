// Prefix module classes with m-

.m-front-page-latest {
	padding: rem(30) rem(20);

	a {
		color: $neutral_90;
		text-decoration: none;
	}

	button, h2, h3 {
		@include font_semibold();
	}

	h2 {
		@include heading_size3();
		margin: 0 0 rem(30) 0;
	}

	h3 {
		@include body_size2();
		margin: 0 0 rem(5) 0;
	}

	p {
		@include body_size1();
		margin: 0;
	}

	&__button {
		@include heading_size1();
		background: none;
		border: none;
		border-bottom: 2px solid transparent;
		cursor: pointer;
		padding: 0;
		transition: border-color 0.3s ease;

		&--active {
			border-color: $accent_light;
		}

	}

	&__cat {

		&:not(:last-child) {
			margin-bottom: rem(10);
		}

	}

	&__col {

		&--minor {
			margin-bottom: rem(20);
		}

	}

	&__item {
		
		&:not(:last-child) {
			border-bottom: 1px solid $neutral_50;
			margin-bottom: rem(20);
			padding-bottom: rem(20);
		}

	}

	&__nav {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	&__slide {
		
	}

	&__slides {

	}

	&__visual {
		background: {
			position: center;
			repeat: no-repeat;
			size: cover;
		}
		display: block;
		margin: 0 0 rem(20) 0;
		padding-bottom: 100%;
		width: 100%;
	}

	@media screen and (min-width:35em) {

		&__item {
			flex: 50%;
			max-width: 50%;
			padding: 0 rem(10);

			&:not(:last-child) {
				border: none;
				padding-bottom: 0;
			}

		}

		&__items {
			display: flex;
			flex-flow: row wrap;
			margin: 0 -10px
		}

	}

	@media screen and (min-width:50em) {
		padding: rem(50) rem(30) rem(40) rem(30);

		&__col {

			&--major {
				flex: 75%;
				max-width: 75%;
			}
	
			&--minor {
				flex: 25%;
				max-width: 25%;
			}
	
		}
	
		&__cols {
			display: flex;
		}

	}

	@media screen and (min-width:60em) {

		&__item {
			flex: 25%;
			max-width: 25%;
		}

	}

	@media screen and (min-width:75em) {
		padding: rem(70) rem(30) rem(60) rem(30);

		h2 {
			@include heading_size4();
		}

		h3 {
			@include heading_size1();
			margin: 0 0 rem(5) 0;
		}

		&__button {
			@include heading_size2();
			border-bottom-width: 4px;
		}

	}

	@media screen and (min-width:90em) {
		padding: rem(70) 0 rem(60) 0;
	}

}