// Prefix module classes with m-

.m-gravityforms-generic {
  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  textarea,
  select {
    border: none;
    border-bottom: 2px solid $accent_light;
    display: inline-block;
  }

  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  textarea {
    @include body_size2();
  }

  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="text"],
  input[type="url"] {
    height: rem(42);
    line-height: rem(40);
    padding: 0 rem(10);

    &::placeholder {
      color: $neutral_50;
    }
  }

  input[type="email"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  textarea,
  select {
    width: 100%;
  }

  input[type="email"],
  input[type="tel"],
  input[type="text"],
  input[type="url"] {
    padding: 0 rem(10);
  }

  input[type="number"] {
    max-width: rem(75);
    min-width: rem(60);
    padding: 0 0 0 rem(10);
    width: auto;
  }

  label {
    @include body_size1();
    display: block;
    margin-top: rem(15);

    a {
      @include font_semibold();
      color: $accent_light;
    }
  }

  select {
    -moz-appearance: window;
    -webkit-appearance: none;
    appearance: none;
    background: {
      color: white;
      image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M5.42162 26.73132l44.60592 44.60592 44.60592-44.60592' fill='none' stroke='#{encodecolor($accent_light)}' stroke-width='14.86864'/%3E%3C/svg%3E");
      position: calc(100% - 10px) center;
      repeat: no-repeat;
      size: rem(16);
    }
    cursor: pointer;
    height: rem(42);
    line-height: rem(40);
    margin-right: rem(20);
    padding: 0 rem(30) 0 rem(10);

    &::-ms-expand {
      display: none;
    }
  }

  textarea {
    height: rem(200);
    padding: rem(10);
  }

  li.gchoice {
    margin: rem(8) 0 0 0;
    position: relative;

    input[type="checkbox"],
    input[type="radio"] {
      left: 0;
      position: absolute;
      top: rem(3);
    }

    label {
      @include body_size1();
      cursor: pointer;
      display: block;
      margin: 0;
      padding: 0 0 0 rem(30);
    }
  }

  .gfield {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: rem(30);
    }

    &:last-child {
      display: none;
    }

    &_checkbox,
    &_radio {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &_description {
      @include body_size1();
    }

    &_error {
      input[type="email"],
      input[type="text"] {
        border-color: red;
      }
    }

    &_label {
      @include font_semibold();
    }

    &_list {
      th {
        @include body_size1();
      }
    }

    &_required {
      color: $accent_light;
    }

    &_validation {
      &_message {
        @include body_size1();
        color: red;
        display: block;
        padding-top: rem(2);
      }
    }
  }

  .gform {
    &_ajax_spinner {
      width: rem(30);
    }

    &_button {
      background: $primary;
      border: 2px solid $primary;
      color: white;
      cursor: pointer;
      display: block;
      line-height: rem(40);
      padding: 0;
      width: rem(150);
    }

    &_fields {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &_fileupload {
      &_rules {
        @include body_size1();
        @include font_semibold();
        display: block;
      }
    }
  }

  .ginput {
    &_complex {
      margin: 0 -10px;

      i {
        display: none;
      }

      label {
        margin-top: rem(15);
      }

      span {
        display: block;
        padding: 0 rem(10);
      }

      .clear-multi {
        display: flex;
      }
    }

    &_container {
      &_consent {
        position: relative;

        input[type="checkbox"] {
          left: 0;
          position: absolute;
          top: rem(3);
        }

        label {
          @include body_size1();
          cursor: pointer;
          display: block;
          margin: 0;
          padding: 0 0 0 rem(30);
        }
      }

      &_date,
      &_time {
        padding: 0 rem(10);
      }

      &_fileupload {
        input[type="file"] {
        }
      }
    }

    &_list {
      margin: 0 -2px;
    }
  }

  .gsection {
    &_title {
      @include font_semibold();
      @include heading_size1();
      border-bottom: 4px solid white;
      color: $accent_light;
      margin: 0;
      padding-bottom: rem(10);
    }
  }

  &_wrapper {
    background: $neutral_30;
    padding: rem(30) rem(20);

    .gform {
      &_description,
      &_title {
        margin: 0 0 rem(15) 0;
      }

      &_description {
        @include body_size3();
        display: block;
      }

      &_heading {
      }

      &_title {
        @include font_semibold();
        @include heading_size2();
        color: $primary;
      }

      &_validation {
        &_error {
          @include font_semibold();
          @include heading_size2();
          color: red;
          margin: 0 0 rem(30) 0;
        }
      }
    }
  }

  &--green {
    .m-gravityforms-generic {
      input[type="email"],
      input[type="number"],
      input[type="tel"],
      input[type="text"],
      input[type="url"],
      textarea,
      select {
        background: tint($accent_light, 50%);
        border-bottom: 2px solid tint($accent_light, 80%);
      }

      label {
        color: white;
      }

      .gfield {
        &_error {
          input[type="email"],
          input[type="text"] {
            border-color: red;
          }
        }
      }

      &_wrapper {
        background: $accent_light;

        .gform {
          &_description,
          &_title {
            color: white;
          }
        }
      }
    }
  }

  @media screen and (min-width: 40em) {
    li.gchoice {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .gfield {
      &_checkbox,
      &_radio {
        display: flex;
        flex-flow: row wrap;
      }
    }

    .ginput {
      &_complex {
        display: flex;
        flex-flow: row wrap;

        i {
          @include heading_size1();
          @include font_semibold();
          color: $neutral_50;
          display: inline-block;
          margin-left: rem(15);
        }

        span {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      &_stripe_creditcard {
        display: block;
      }
    }
  }

  @media screen and (min-width: 50em) {
    li.gchoice {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }

    &_wrapper {
      padding: rem(50);
    }
  }

  @media screen and (min-width: 70em) {
    &_wrapper {
      padding: rem(70);
    }
  }
}

.m-gravityforms-join {
  padding-bottom: rem(30);

  input[type="email"],
  input[type="text"] {
    border: 2px solid white;
    display: block;
    height: rem(44);
    line-height: rem(40);
    padding: 0 rem(10);
    width: 100%;
  }

  label {
    @include body_size1();
    display: block;
  }

  .gfield {
    margin-bottom: rem(20);

    &:last-child {
      display: none;
    }

    &_error {
      input[type="email"],
      input[type="text"] {
        border-color: red;
      }
    }

    &_label {
      &_before_complex {
        display: none;
      }
    }

    &_required {
      display: none;
    }

    &_validation_message {
      display: none;
    }
  }

  .gform {
    &_button {
      background: $primary;
      border: 2px solid $primary;
      color: white;
      cursor: pointer;
      display: block;
      line-height: rem(40);
      padding: 0;
      width: rem(150);
    }

    &_fields {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  .ginput {
    &_complex {
      display: flex;
      margin: 0 -10px;

      span {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 rem(10);
      }
    }
  }

  &_wrapper {
    padding: 0 rem(20);

    .gform {
      &_title {
        @include font_semibold();
        @include heading_size1();
        color: white;
        margin: 0;
        padding: rem(30) 0;
      }

      &_validation_errors {
        display: none;
      }
    }
  }

  @media screen and (min-width: 50em) {
    padding-bottom: rem(50);

    &_wrapper {
      padding: 0 rem(30);

      .gform {
        &_title {
          padding: rem(50) 0 rem(30) 0;
        }
      }
    }
  }

  @media screen and (min-width: 70em) {
    padding-bottom: rem(70);

    &_wrapper {
      .gform {
        &_title {
          padding: rem(70) 0 rem(30) 0;
        }
      }
    }
  }
}

.m-gravityforms-signup {
  padding-bottom: rem(30);

  input[type="email"],
  input[type="text"] {
    border: 2px solid white;
    display: block;
    height: rem(44);
    line-height: rem(40);
    padding: 0 rem(10);
    width: 100%;
  }

  label {
    @include body_size1();
    display: block;
  }

  .gfield {
    margin-bottom: rem(20);

    &:last-child {
      display: none;
    }

    &_error {
      input[type="email"],
      input[type="text"] {
        border-color: red;
      }
    }

    &_label {
      &_before_complex {
        display: none;
      }
    }

    &_required {
      display: none;
    }

    &_validation_message {
      display: none;
    }
  }

  .gform {
    &_button {
      background: $primary;
      border: 2px solid $primary;
      color: white;
      cursor: pointer;
      display: block;
      line-height: rem(40);
      padding: 0;
      width: rem(150);
    }

    &_fields {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  .ginput {
    &_complex {
      display: flex;
      margin: 0 -10px;

      span {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 rem(10);
      }
    }
  }

  &_wrapper {
    margin: 0 auto;
    max-width: rem(940);
    padding: 0 rem(20);

    .gform {
      &_title {
        @include font_semibold();
        @include heading_size2();
        color: $primary;
        margin: 0;
        padding: rem(30) 0 rem(30) 0;
      }

      &_validation_errors {
        display: none;
      }
    }
  }

  @media screen and (min-width: 50em) {
    display: flex;
    align-items: flex-end;
    padding-bottom: rem(50);

    .gfield {
      flex: 0 0 33.333%;
      margin-bottom: 0;
      max-width: 33.333%;

      &:not(.gfield--large) {
        padding-right: rem(20);
      }

      &--large {
        flex: 0 0 66.666%;
        max-width: 66.666%;
      }
    }

    .gform {
      &_body {
        width: calc(100% - 150px);
      }

      &_fields {
        display: flex;
      }

      &_footer {
        width: rem(150);
      }
    }

    .ginput {
      &_complex {
        margin: 0;

        span {
          padding: 0 rem(20) 0 0;
        }
      }
    }

    &_wrapper {
      padding: 0 rem(30);

      .gform {
        &_title {
          padding: rem(50) 0 rem(40) 0;
        }
      }
    }
  }

  @media screen and (min-width: 70em) {
    padding-bottom: rem(70);

    &_wrapper {
      .gform {
        &_title {
          padding: rem(70) 0 rem(40) 0;
        }
      }
    }
  }

  @media screen and (min-width: 75em) {
    &_wrapper {
      padding: 0;
    }
  }
}
