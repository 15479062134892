// Prefix module classes with m-

.m-footer {
  @include body_size1();
  padding-top: rem(30);

  &,
  a {
    color: tint($primary, 70%);
  }

  a {
    text-decoration: none;
    transition: color 0.3s ease;

    &:focus,
    &:hover {
      color: $accent_light;
    }
  }

  &__address {
    display: block;
    margin: 0 0 rem(20) 0;
  }

  &__col {
    padding-bottom: rem(30);
  }

  &__cols {
    padding: 0 rem(20);
  }

  &__contact {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  &__copyright {
    &,
    a {
      color: white;
    }

    p {
      margin: 0;

      &:first-child {
        margin-bottom: rem(14);
      }
    }
  }

  &__inner {
    background: {
      image: url(#{$res_path}/img/flower.svg);
      position: bottom center;
      repeat: no-repeat;
      size: rem(70);
    }
    padding-bottom: rem(140);
  }

  &__legals {
    margin-top: rem(20);
  }

  &__logo {
    height: rem(40);
    margin-bottom: rem(40);
    width: rem(120);

    svg {
      g {
        fill: white;
      }
    }
  }

  &__signup {
    background: $accent_light;
  }

  @media screen and (min-width: 40em) {
    &__col {
      flex: 0 0 50%;
      max-width: 50%;

      &--large {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &__cols {
      display: flex;
      flex-flow: row wrap;
    }
  }

  @media screen and (min-width: 50em) {
    padding-top: rem(50);

    &__cols {
      padding: 0 rem(30);
    }
  }

  @media screen and (min-width: 60em) {
    &__col {
      flex: 0 0 20%;
      padding-bottom: 0;
      max-width: 20%;
      position: relative;

      &--large {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
  }

  @media screen and (min-width: 70em) {
    padding-top: rem(70);

    &__address {
      margin: 0 0 rem(40) 0;
    }

    &__col {
      flex: 0 0 20%;
      max-width: 20%;
      position: relative;

      &--large {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
  }

  @media screen and (min-width: 75em) {
    &__cols {
      padding: 0;
    }
  }

  @media screen and (min-width: 80em) {
    &__inner {
      background: {
        position: calc(100% - 30px) bottom;
      }
      padding-bottom: rem(30);
    }
  }

  @media screen and (min-width: 90em) {
    &__inner {
      background: {
        position: bottom right;
        size: rem(100);
      }
      padding-bottom: rem(70);
    }
  }
}
