// Prefix module classes with m-

.m-events {
	padding: 0 rem(20) rem(50) rem(20);

	&__author {
		color: $secondary;
		margin: 0 0 rem(20) 0;
	}

	&__col {
		flex: 0 0 100%;
		max-width: 100%;

		&:first-child {
			padding-bottom: rem(20);
		}

	}

	&__cols {
		background: white;
		box-shadow: 0px 0px 10px 5px $neutral_30;
		display: flex;
		flex-flow: row wrap;
		flex-direction: row-reverse;
		height: 100%;
		padding: rem(20);
	}

	&__cta {
		background: $primary;

		a {
			display: inline-block;
			text-decoration: none;
		}

		h2 {
			@include font_semibold();
			@include heading_size3();
			color: $accent_light;
			margin: 0 0 rem(20) 0;
		}

		p {
			@include body_size3();
			color: white;
			margin: 0;
		}

		&-heading {
			background: {
				position: top right;
				repeat: no-repeat;
				size: contain;
			}
			padding: rem(100) 0;
		}

	}

	&__date {
		margin: 0;

		a {
			color: $neutral_70;
		}

	}

	&__featured {
		padding-top: rem(50);

		a {
			text-decoration: none;
		}

		h2 {
			@include body_size3();
			background: $primary;
			color: white;
			display: inline-block;
			margin: 0;
			padding: rem(10) rem(20);
			position: absolute;
			right: rem(20);
			top: -25px;
		}

		h3 {
			@include heading_size2();
			margin: 0 0 rem(20) 0;

			a {
				color: $primary;
			}

		}

		&_col {

		}

		&-cols {
			background: $accent_light;
			display: flex;
			flex-flow: row wrap;
			padding: rem(40) rem(20) rem(20) rem(20);
			position: relative;
		}

		&-info {
			width: 100%;

			p {
				@include body_size3();
				margin: rem(30) 0 rem(5) 0;

				a {
					color: white;
				}

			}

		}

		&-price {
			width: 100%;

			p {
				@include body_size3();
				color: white;
				line-height: 1;
				margin: 0;
			}

		}

		&-visual {
			width: 100%;

			a {
				background: {
					position: center;
					repeat: no-repeat;
					size: cover;
				}
				display: block;
				margin: 0;
				padding-bottom: 100%;
				width: 100%;
			}

		}

	}

	&__item {
		flex: 0 0 100%;
		margin-bottom: rem(20);
		max-width: 100%;
		padding: 0 rem(10);

		a {
			text-decoration: none;
		}

		h2 {
			@include heading_size2();
			margin: 0 0 rem(20) 0;

			a {
				color: $accent_light;
			}

		}

		hr {
			border: none;
			border-top: 4px solid $accent_light;
			margin: 0 0 rem(20) 0;
			text-align: left;
			width: rem(40);
		}

		&-visual {
			background: {
				position: center;
				repeat: no-repeat;
				size: cover;
			}
			display: block;
			margin: 0;
			padding-bottom: 100%;
			width: 100%;
		}

	}

	&__reveal {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -10px;
	}

	.alm-btn-wrap {
		padding-top: rem(50);
		text-align: center;

		button {
			@include body_size3();
			background: {
				color: transparent;
				image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' width='100' height='100' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M5.42162 26.73132l44.60592 44.60592 44.60592-44.60592' fill='none' stroke='%23000' stroke-width='14.86864'/%3E%3C/svg%3E");
				position: center right;
				repeat: no-repeat;
				size: rem(16);
			}
			border: none;
			color: $primary;
			cursor: pointer;
			padding-right: rem(30);

			&:disabled {
				color: $primary;
				opacity: 0.2;
			}

		}

	}

	@media screen and (min-width:35em) {

		&__col {

			&:first-child {
				flex: 0 0 33.333%;
				max-width: 33.333%;
				padding-bottom: 0;
			}

			&:last-child {
				flex: 0 0 66.666%;
				max-width: 66.666%;
				padding-right: rem(30);
			}

		}

		&__cols {
			padding: rem(50) rem(30);
		}

	}

	@media screen and (min-width:40em) {
		padding: 0 rem(30) rem(50) rem(30);

		&__featured {
			padding: rem(50) 0 0 rem(50);

			h2 {
				right: rem(50);
			}

			h3 {
				margin: 0;
			}

			&-cols {
				padding: rem(20) 0;
			}

			&-info {
				padding-left: rem(20);
				width: calc(75% - 120px);
			}

			&-price {
				align-self: flex-end;
				width: 25%;
			}

			&-visual {
				margin-left: -50px;
				width: rem(170);
			}

		}

		&__item {
			flex: 0 0 50%;
			max-width: 50%;
		}

	}

	@media screen and (min-width:60em) {
		padding: 0 0 rem(50) 0;

		&__featured {

			h3 {
				@include heading_size3();
			}

			&-info {
				padding-left: rem(50);
				width: calc(75% - 200px);

				p {
					margin: rem(30) 0 rem(20) 0;
				}

			}

			&-visual {
				margin-left: -50px;
				width: rem(250);
			}

		}

	}

}